.capabilities {
  box-sizing: border-box;
  margin-bottom: 42px;
  min-height: 760px;
}

.container {
  box-sizing: border-box;
  padding: 0 15px;
  max-width: 1120px;
  margin: 0 auto;
}

.capabilities h2 {
  padding-top: 42px;
  margin-bottom: 12px;
  font-size: 28px;
  line-height: 36px;
}

.capabilities p {
  margin-bottom: 32px;
  font-size: 17px;
  line-height: 24px;
}

@media (max-width: 1200px) {
  .capabilities {
    min-height: 100%;
  }
}

@media (max-width: 376px) {
  .capabilities {
    min-height: 100%;
  }
  .capabilities h2 {
    margin-bottom: 8px;
    font-size: 20px;
    line-height: 26px;
    padding-top: 32px;
  }
  .capabilities p {
    font-size: 15px;
    line-height: 22px;
  }
}
