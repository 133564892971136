.documents {
  margin-bottom: 80px;
}

.container {
  box-sizing: border-box;
  align-items: center;
  width: 100%;
  max-width: 1120px;
  margin: 0 auto;
  padding: 0 15px;
}

.documents h2 {
  margin-bottom: 32px;
  font-size: 28px;
  line-height: 36px;
}

.documents_loader {
  display: flex;
}

.documents_wrapper {
  margin-left: 20px;
}

.documents_wrapper a {
  font-size: 15px;
  line-height: 22px;
  color: #242729;
  text-decoration: none;
}

.documents_wrapper span {
  display: block;
  margin-top: 8px;
  font-size: 13px;
  line-height: 18px;
  color: #919399;
}

@media (max-width: 376px) {
  .documents h2 {
    font-size: 20px;
    line-height: 26px;
    padding-top: 32px;
  }
}
