.main-banner {
  background: #f7f7f7;
  min-height: 492px;
}

.main-banner-title {
  max-width: 544px;
  padding-top: 192px;
}

.main-banner-title h1 {
  margin-bottom: 12px;
  font-size: 36px;
  line-height: 44px;
  font-weight: 700;
}

.main-banner-title p {
  margin-bottom: 25px;
  font-size: 17px;
  line-height: 24px;
}

.main-banner-img {
  background-image: url("../../assets/images/phone.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position-y: bottom;
  max-width: 544px;
  width: 100%;
  height: 544px;
}

.main-banner-qr {
  display: flex;
  justify-content: space-between;
  max-width: 328px;
}

.main-banner-qr-wrapper {
  max-width: 208px;
}

.main-banner-qr-wrapper p {
  margin-bottom: 32px;
  font-size: 11px;
  line-height: 16px;
  color: #919399;
}

.main-banner-qr-wrapper a {
  color: #242729;
  text-decoration: none;
}

.main-banner-qr-wrapper a > svg {
  width: 40px;
  height: 40px;
  box-shadow: 0 0 16px rgba(79, 105, 118, 0.1);
}

.download {
  box-sizing: border-box;
  text-decoration: none;
  height: 40px;
  width: 90px;
  background: #dd0a34;
  border-radius: 8px;
  display: none;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  line-height: 22px;
  color: #fff;
}

@media (max-width: 1200px) {
  .main-banner {
    min-height: 100%;
  }

  .main-banner > div {
    max-width: 100%;
  }
  .main-banner-title {
    margin-bottom: 46px;
    padding-top: 122px;
    display: flex;
    flex-direction: column;
    align-self: flex-start;
  }
  .main-banner-title h1 {
    font-size: 24x;
    line-height: 38px;
    width: 260px;
  }

  .main-banner-title p {
    margin-bottom: 16px;
    font-size: 15px;
    line-height: 22px;
  }

  .main-banner-img {
    height: 468px;
    background-position-x: center;
  }

  .download {
    display: flex;
  }

  .main-banner-qr {
    display: none;
  }
}

@media (max-width: 376px) {
  .main-banner {
    min-height: 100%;
  }
  .download {
    display: flex;
  }
  .main-banner > div {
    flex-direction: column;
    max-width: 100%;
  }
  .main-banner-title {
    margin-bottom: 46px;
    padding-top: 22px;
    display: flex;
    flex-direction: column;
    align-self: flex-start;
  }
  .main-banner-title h1 {
    margin-bottom: 8px;
    font-size: 20px;
    line-height: 26px;
    width: 280px;
  }
  .main-banner-title p {
    margin-bottom: 16px;
    font-size: 15px;
    line-height: 22px;
  }
  .main-banner-img {
    height: 240px;
    background-position-x: center;
  }
  .main-banner-qr {
    display: none;
  }
}
