.container {
  box-sizing: border-box;
  align-items: center;
  width: 100%;
  max-width: 1120px;
  margin: 0 auto;
  padding: 0 15px;
}

.instruction {
  min-height: 362px;
  margin-bottom: 80px;
}

.instruction h2 {
  font-size: 28px;
  line-height: 36px;
  font-weight: 700;
  margin-bottom: 32px;
}

.instruction__note {
  margin-top: 32px;
  font-size: 13px;
  line-height: 18px;
  color: #919399;
}

@media (max-width: 1200px) {
  .instruction {
    margin-top: 0px;
    height: 300px;
  }
}

@media (max-width: 376px) {
  .instruction {
    margin-top: 0px;
    height: 300px;
  }
  .instruction h2 {
    font-size: 20px;
    line-height: 26px;
  }
  .instruction__note {
    font-size: 11px;
    line-height: 16px;
  }
}
