.navigation a {
  margin-right: 24px;
  font-size: 13px;
  line-height: 18px;
  color: #6d6d73;
  text-decoration: none;
  border-bottom: 2px solid transparent;
  transition: border-bottom 0.35s, color 0.35s;
}

.navigation a:hover {
  color: #242729;
  border-bottom: 2px solid #dd0a34;
}

.navigation:last-child {
  margin-right: 0;
}

@media (max-width: 1200px) {
  .navigation {
    display: none;
  }
}
