.footer {
  height: 113px;
  background-color: #0c0c0c;
}

.footer span {
  font-weight: 400;
  font-size: 11px;
  line-height: 14px;
  color: #fff;
}

.container {
  display: flex;
  width: 100%;
  height: 100%;
  max-width: 1120px;
  margin: 0 auto;
  justify-content: space-between;
  align-items: center;

  box-sizing: border-box;
  padding: 0 15px;
}

@media (max-width: 376px) {
  .container {
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }

  .footer span {
    padding-top: 10px;
  }
}
