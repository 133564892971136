.instruction {
  width: 256px;
}
.instruction-img-wrap {
  width: 100%;
  height: 154px;
  background: #f7f7f7;
  border-radius: 8px;
  margin-bottom: 20px;
}

.instruction-wrap {
  display: flex;
  align-items: center;
  padding-right: 15px;
}

.instruction-wrap h3 {
  margin-right: 17px;
  font-size: 48px;
  line-height: 52px;
  font-weight: 300;
  color: #dd0a34;
}

.instruction-wrap p {
  font-size: 15px;
  line-height: 22px;
}

.instruction-img-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 1200px) {
  .instruction {
    width: 100%;
  }

  .instruction-wrap {
    align-items: flex-start;
  }

  .instruction-wrap p {
    font-size: 12px;
    line-height: 16px;
  }
  .instruction-img-wrap {
    width: fit-content;
    padding: 0 8px;
    margin: 0 auto 20px;
  }
}

@media (max-width: 376px) {
  .instruction {
    margin: 0 auto;
    margin-bottom: 20px;
  }

  .instruction-img-wrap {
    width: fit-content;
    padding: 0 8px;
    margin: 0 auto 20px;
  }
}
