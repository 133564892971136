body,
button,
h1,
h2,
h3,
h4,
h5,
h6,
html,
ol,
p,
ul {
  margin: 0;
  padding: 0;
}

@font-face {
  font-family: "Gratimo Grotesk";
  src: url("./assets/fonts/GratimoGrotesk-Regular.ttf");
}

html {
  scroll-behavior: smooth;
}

body {
  font-family: "Gratimo Grotesk", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.swiper-slide-thumb-active > div {
  background-color: #f7f7f7;
  border-color: #f7f7f7;
}

.swiper-custom-pagination {
  text-align: center;
  transition: opacity 0.3s;
  transform: translateZ(0);
  z-index: 10;
  margin-top: 10px;
}

.swiper-custom-pagination-instruction {
  text-align: center;
  transition: opacity 0.3s;
  transform: translateZ(0);
  z-index: 10;
  margin-top: 10px;
}

.swiper-custom-pagination .swiper-pagination-bullet-active {
  background-color: #dd0a34;
}

.swiper-custom-pagination-instruction .swiper-pagination-bullet-active {
  background-color: #dd0a34;
}
