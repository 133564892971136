.app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  line-height: 1.2;
  color: #242729;
  background: #fff;
}
