.slider {
  margin-top: 50px;
  height: 428px;
}

.swiper-slide__item {
  width: 352px;
  height: 140px;
  padding: 24px;
  border-radius: 8px;
  box-sizing: border-box;
  border: 1px solid #e4e8ed;
  transition: background-color 0.3s, border-color 0.3s;
}

.swiper-slide__item:hover {
  cursor: pointer;
  background-color: #f7f7f7;
  border-color: #f7f7f7;
}

.swiper-slide__item-img {
  margin-bottom: 13px;
}

.swiper-slide__item-title {
  max-width: 208px;
  font-size: 15px;
  line-height: 22px;
}

.slider__content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}

.slider__content-wrapper {
  max-width: 496px;
}

.slider__content-wrapper h5 {
  margin-bottom: 16px;
  font-size: 20px;
  line-height: 26px;
  font-weight: 700;
}

.slider__content-wrapper p {
  font-size: 17px;
  line-height: 24px;
}

.slider__content-img {
  background-repeat: no-repeat;
  background-size: contain;
  background-position-y: bottom;
  max-width: 544px;
  width: 100%;
  height: 100%;
}

.mortgage {
  background-image: url("../../assets/images/mortgage.png");
}

.signature {
  background-image: url("../../assets/images/signature.png");
}

.marked {
  background-image: url("../../assets/images/marked.png");
}

@media (max-width: 1200px) {
  .slider {
    margin-top: 0px;
    height: 398px;
  }

  .capabilities-slider {
    display: none;
  }

  .slider__content-img {
    max-width: 455px;
  }
}

@media (max-width: 376px) {
  .capabilities-slider {
    display: none;
  }
  .slider {
    margin-top: 0px;
    height: 300px;
  }
  .slider__content-wrapper h5 {
    margin-top: 8px;
    font-size: 17px;
    line-height: 24px;
  }
  .slider__content-wrapper p {
    font-size: 13px;
    line-height: 18px;
  }
  .slider__content {
    flex-direction: column-reverse;
  }

  .slider__content-img {
    height: 194px;
    background-position-x: center;
  }
}
